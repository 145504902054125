import InitialState from '../initialState';
import { createReducer } from '@reduxjs/toolkit';
import { resetIngestionAction, checkIfObjectIsEmpty } from '../../utils/generic/helper';
import validateTabs from '../../utils/generic/validation';
import {
  deleteRegistrySuccess,
  getRegistryMasterDataSuccess,
  registryFeatureSelection,
  registryNextFeatureSelection,
  setSelectedTabIndex,
  saveRegistry,
  saveRegistrySuccess,
  resetRegistryDetails,
  resetRegistryState,
  getRegistryDetailsSuccess,
  saveRegistryDetails,
  saveRegistryDetailsSuccess,
  updateRegistryUrl,
  setProtocolRegistration,
  setResultsDisclosure,
  validateRegistrySuccess,
  setErrorDetailsRegistry,
  setRegistryValidationStatus,
  setRegistryUnSavedStatus,
  setValidate,
  getStagedIdRegistrySuccess,
  moveOutofScopeRegistrySuccess,
  registryRecordChangeFldsListSuccess,
  getRegistryStampingDetailsSuccess,
  updateRegistryRecordDatesDetail,
  getRegistryUserLockDetails,
  resetRegistryUserLock,
  resetStagedIdRegistry,
} from '../actions/registry';
import { getRegistryDetailsForUI } from '../helpers/registry'; //updateRegistryStampingDetails
import { REGISTRY_TYPES } from '../../utils/generic/constants';

const initialState = InitialState.registry;
const registry = createReducer(initialState, {
  [registryFeatureSelection]: (state, { payload: { value } }) => {
    state.selected = value;
  },
  [registryNextFeatureSelection]: (state, { payload }) => {
    const filteredTabs = state.tabs.filter(i => i.value === payload);
    const index = state.tabs.findIndex(i => i.value === payload);
    const isSingleModeActive = state.isSinglePageMode;
    if (filteredTabs.length > 0) {
      const { isTab, selectedTabIndex, tabs } = filteredTabs[0];
      if (isTab) {
        if (selectedTabIndex < tabs.length - 1) {
          state.tabs[index].selectedTabIndex = selectedTabIndex + 1;
        } else if (selectedTabIndex === tabs.length - 1) {
          const tabValue = state.tabs[index + 1].value;
          isSingleModeActive ? (state.previewSelected = tabValue) : (state.selected = tabValue);
          state.tabs[index].selectedTabIndex = 0;
        }
      } else if (isSingleModeActive) {
        state.previewSelected = state.tabs[index + 1].value;
      } else {
        state.selected = state.tabs[index + 1].value;
      }
    }
  },
  [setSelectedTabIndex]: (state, { payload: { id, tabIndex } }) => {
    const index = state.tabs.findIndex(i => i.value === id);
    if (index > -1) {
      state.tabs[index].selectedTabIndex = tabIndex;
    }
  },
  [deleteRegistrySuccess]: (state, { payload }) => {
    const results = state.dashboard.queues.queueRecords.results;
    state.dashboard.queues.queueRecords.results = results.filter(entry => entry.id !== payload);
  },
  [getRegistryMasterDataSuccess]: (state, { payload }) => {
    state.masterData = payload;
  },
  [saveRegistryDetails]: (state, { payload, payload: { key } }) => {
    const selected = state.tabs.find(tab => tab.key === key);
    const tabId = REGISTRY_TYPES[Object.keys(payload)[0]];
    state.tabs[tabId - 1].hasUnSavedData = false;
    // state.tabs[tabId - 1].hasError = false;
    // state.tabs[tabId - 1].error = null;
    if (selected) {
      selected.hasError = false;
    }
  },

  [saveRegistryDetailsSuccess]: (state, { payload }) => {
    state.registryData = payload;
    state.current = payload;
    state.original = payload;
    state.isStamped = false;
    // for (let i = 0; i < state.tabs.lengtph; i++) {
    //   state.tabs[i].hasError = false;
    //   state.tabs[i].hasUnSavedData = false;
    //   state.tabs[i].error = null;
    // }
  },
  [updateRegistryUrl]: (state, { payload }) => {
    state.generalInformation.registryURL = payload.data.updatedSupportingUrls;
  },

  [resetRegistryDetails]: (state, { payload }) => {
    const selected = state.tabs.find(tab => tab.key === payload);
    if (selected && selected.hasIngestedData) {
      selected.hasUnSavedData = false;
      const { ingestedData, updatedCurrentData } = resetIngestionAction(
        state.ingested[payload],
        state.current[payload],
        state.original[payload],
      );
      state.ingested[payload] = ingestedData;
      state.current[payload] = updatedCurrentData;
    } else {
      if (selected) {
        selected.hasUnSavedData = false;
        selected.hasError =
          selected.hasError &&
          Boolean(validateTabs.person[payload]) &&
          !checkIfObjectIsEmpty(
            validateTabs.registry[payload](state.original[payload], state.original),
          );
      }
      state.current[payload] = state.original[payload];
    }
  },
  [resetRegistryState]: state => {
    const { selected, original, current, tabs } = initialState;
    state.selected = selected;
    state.tabs = tabs;
    state.original = original;
    state.current = current;
    state.isStamped = false;
  },
  [getRegistryDetailsSuccess]: (state, { payload }) => {
    const registryDetails = getRegistryDetailsForUI(payload);
    state.original = { ...state.original, ...registryDetails };
    state.current = { ...state.current, ...registryDetails };

    // state.originalStampDates = getPersonStampDates(state.original.personBasicInfo.recordDates);
  },
  [setProtocolRegistration]: (state, { payload }) => {
    state.protocolRegistration = { ...state.protocolRegistration, ...payload };
  },
  [setResultsDisclosure]: (state, { payload }) => {
    state.resultsDisclosure = { ...state.resultsDisclosure, ...payload };
  },
  [validateRegistrySuccess]: (state, { payload }) => {
    state.original = payload;
    state.current = payload;
  },
  [setErrorDetailsRegistry]: (state, data = {}) => {
    const index = data.payload.tabs.findIndex(i => i.key === data.payload.tabIndex);
    state.tabs[index].error = data.payload.errorDetails;
  },
  [setRegistryUnSavedStatus]: (state, { payload }) => {
    state.tabs[payload?.tabId].hasUnSavedData = true;
  },

  [setRegistryValidationStatus]: (state, { payload }) => {
    const { tabId, hasError } = payload;
    state.tabs[tabId].hasError = hasError;
  },
  [setValidate]: (state, { payload }) => {
    const { tabId, validate } = payload;
    state.tabs[tabId].validate = validate;
  },
  [getStagedIdRegistrySuccess]: (state, { payload }) => {
    state.stageId = payload;
  },
  [moveOutofScopeRegistrySuccess]: (state, { payload }) => {
    state.original = payload;
    state.current = payload;
  },
  [registryRecordChangeFldsListSuccess]: (state, { payload }) => {
    state.changeFields = payload;
  },
  [getRegistryStampingDetailsSuccess]: (state, { payload: { data } }) => {
    state.isStamped = true;
    state.current = data;
    state.original = data;
  },
  [updateRegistryRecordDatesDetail]: (state, { payload: { key, data } }) => {
    state.registryData[key] = data;
    state.current[key] = data;
    state.original[key] = data;
  },
  [getRegistryUserLockDetails]: (state, { payload: { isEditable, lockedBy, userLockPeriod } }) => {
    const updatedData = {
      isLocked: !isEditable,
      lockedBy,
      userLockPeriod,
    };
    state.registryLockDetails = updatedData;
  },
  [resetRegistryUserLock]: state => {
    const { registryLockDetails } = initialState;
    state.registryLockDetails = registryLockDetails;
  },
  [resetStagedIdRegistry]: state => {
    state.stageId = null;
  },
});

export default registry;
