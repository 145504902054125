/* eslint-disable generator-star-spacing */
import { takeEvery, put, select, all } from 'redux-saga/effects';
import * as registryAction from '../actions/registry';
import * as globalActions from '../actions/globalActions';
import { registry as registryApi } from '../../api';
import { ALERT_MESSAGES, MODAL_TYPES, REGISTRY_TYPES } from '../../utils/generic/constants';
import { getLockMessageInfo, getApiErrorMessage } from '../../utils/generic/helper';

export function* getRegistryAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const { id } = payload;
    const response = yield registryApi.getRegistryDetails(id);
    const { state, data, messages } = response.data;
    if (state) {
      yield put(registryAction.saveRegistryDetailsSuccess(data));
      yield put(registryAction.getRegistryDetailsSuccess(data));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(registryAction.setError());
  }
}

// export function* saveRegistryAsync({ payload }) {
//   try {
//     yield put(globalActions.showSpinner());
//     const { registryData } = yield select((state) => state.registry)
//     const { id = 102, registryName = null } = registryData
//     const forceUpdate = [REGISTRY_TYPES[Object.keys(payload)[0]]]
//     const response = yield registryApi.saveRegistryDetails({
//       id,
//       forceUpdate,
//       registryName,
//       ...payload
//     });
//     const { state, data, messages } = response.data;
//     if (state) {
//       yield put(registryAction.saveRegistrySuccess(data));
//       yield put(globalActions.showAlert(ALERT_MESSAGES.REGISTRY_SAVE));
//     } else {
//       const modalData = {
//         isOpen: true,
//         message:
//           messages && messages.length
//             ? messages[0].errorMessage
//             : ALERT_MESSAGES.REGISTRY_ERROR.message,
//         modalType: MODAL_TYPES.ERROR,
//       };
//       yield put(globalActions.showModal(modalData));
//     }
//     yield put(globalActions.hideSpinner());
//   } catch (err) {
//     yield put(globalActions.hideSpinner());
//     yield put(registryAction.setError());
//   }
// }

export function* saveRegistryAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const { registryData = {} } = yield select(state => state.registry);
    const { id = 0, registryName = null } = registryData;
    const forceUpdate = [REGISTRY_TYPES[Object.keys(payload)[0]]];
    const response = yield registryApi.saveRegistryDetails({
      id,
      forceUpdate,
      registryName,
      ...payload,
    });
    const { state, data, messages } = response.data;
    if (state) {
      yield put(registryAction.saveRegistryDetailsSuccess(data));
      yield put(globalActions.showAlert(ALERT_MESSAGES.REGISTRY_SAVE));
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.REGISTRY_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(registryAction.setError());
  }
}

export function* deleteRegistryAsync({ payload: { registryId, postParam, productionStatusId } }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield registryApi.deleteRegistry(registryId, productionStatusId);
    yield put(globalActions.hideSpinner());
    const {
      data: { state, messages },
    } = response;

    if (state) {
      // yield put(registryAction.getRegistryList(postParam));
      yield put(globalActions.showAlert(ALERT_MESSAGES.REGISTRY_DELETE));
      window.location.reload();
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.REGISTRY_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
      yield put(globalActions.hideSpinner());
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(
      globalActions.showModal({
        isOpen: true,
        message: err.message,
        modalType: MODAL_TYPES.ERROR,
      }),
    );
  }
}
export function* getRegistryMasterDataAsync() {
  try {
    yield put(globalActions.showSpinner());

    const response = yield registryApi.getRegistryMasterData();

    const { state, data } = response.data;
    if (state) {
      yield put(registryAction.getRegistryMasterDataSuccess(data));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(registryAction.setError());
  }
}

export function* getRegistryDetailsAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const { id } = payload;
    let actions = {
      masterActions: registryApi.getRegistryMasterData(),
    };

    // if (ingestedId > 0) {
    //   actions = { ...actions, ingestedActions: registryApi.getIngestedData(ingestedId) };
    // }

    if (id > 0) {
      actions = {
        ...actions,
        registryDetailsActions: registryApi.getRegistryDetails(id),
      };
    }

    const { masterActions, registryDetailsActions } = yield all(actions);

    if (masterActions) {
      const { state: masterState, data: masterData } = masterActions.data;
      if (masterState) {
        yield put(registryAction.getRegistryMasterDataSuccess(masterData));
      }
    }

    if (registryDetailsActions) {
      const { state: registryState, data: registryData } = registryDetailsActions.data;
      if (registryState) {
        yield put(registryAction.getRegistryDetailsSuccess(registryData));
        yield put(registryAction.saveRegistryDetailsSuccess(registryData));
      }
      if (registryData?.productionStatus !== 2) {
        const lockDetails = yield registryApi.getUserLockDetails(id);
        if (lockDetails) {
          const {
            data: { state, data, messages },
          } = lockDetails;
          if (state) {
            yield put(registryAction.getRegistryUserLockDetails(data));
            const { isEditable, lockedBy } = data;
            if (!isEditable) {
              yield put(
                globalActions.showModal({
                  isOpen: true,
                  message: getLockMessageInfo(lockedBy),
                  modalType: MODAL_TYPES.INFO,
                }),
              );
            }
          } else {
            yield put(
              globalActions.showModal({
                isOpen: true,
                message: getApiErrorMessage(messages),
                modalType: MODAL_TYPES.ERROR,
              }),
            );
          }
        }
      }
    }

    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(registryAction.setError());
  }
}

export function* validateRegistryAsync() {
  try {
    yield put(globalActions.showSpinner());
    const { original = {} } = yield select(state => state.registry);
    const { id = 0, registryName = null } = original;
    const forceUpdate = [9];
    const productionStatus = 1;
    const response = yield registryApi.saveRegistryDetails({
      id,
      forceUpdate,
      registryName,
      productionStatus,
    });
    const { state, data, messages } = response.data;
    if (state) {
      yield put(registryAction.saveRegistryDetailsSuccess(data));
      yield put(globalActions.showAlert(ALERT_MESSAGES.REGISTRY_VALIDATE));
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.COUNTRY_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(registryAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.REGISTRY_ERROR));
  }
}

export function* publishRegistryAsync({ payload }) {
  try {
    const { auditProperties } = payload;
    yield put(globalActions.showSpinner());
    const { original = {} } = yield select(state => state.registry);
    const { id = 0 } = original;
    const forceUpdate = [9];
    const productionStatus = 2;
    const response = yield registryApi.saveRegistryDetails({
      id: payload?.id,
      forceUpdate,
      productionStatus,
      auditProperties,
    });
    const { state, data, messages } = response.data;
    if (state) {
      yield put(registryAction.saveRegistryDetailsSuccess(data));
      yield put(globalActions.showAlert(ALERT_MESSAGES.REGISTRY_PUBLISHED));
      if (auditProperties) {
        window.location.href = '/dashboard';
      } else {
        window.location.reload();
      }
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.COUNTRY_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(registryAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.REGISTRY_ERROR));
  }
}

export function* moveOutofScopeRegistryAsync() {
  try {
    yield put(globalActions.showSpinner());
    const { original = {} } = yield select(state => state.registry);
    const { id = 0 } = original;
    const forceUpdate = [9]; // forceupate should be 9 for registry
    const productionStatus = 3;
    const response = yield registryApi.saveRegistryDetails({
      id,
      forceUpdate,
      productionStatus,
    });
    const { state, data, messages } = response.data;
    const delay = ms => new Promise(res => setTimeout(res, ms));
    if (state) {
      put(registryAction.saveRegistryDetailsSuccess(data));
      yield put(globalActions.showAlert(ALERT_MESSAGES.SOURCEDOCUMENT_OUTOFSCOPE));
      yield delay(2000);
      window.location.reload();
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.SOURCEDOCUMENT_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(registryAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.SOURCEDOCUMENT_ERROR));
  }
}

export function* getStagedIdRegistryAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const id = payload;
    const response = yield registryApi.getStagedIdRegistry(id);
    const { state, data, messages } = response.data;
    if (state) {
      yield put(registryAction.getStagedIdRegistrySuccess(data));
    } else {
      const modalData = {
        isOpen: true,
        message:
          messages && messages.length
            ? messages[0].errorMessage
            : ALERT_MESSAGES.SOURCEDOCUMENT_ERROR.message,
        modalType: MODAL_TYPES.ERROR,
      };
      yield put(globalActions.showModal(modalData));
      yield put(globalActions.hideSpinner());
    }
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(registryAction.setError());
    yield put(globalActions.showAlert(ALERT_MESSAGES.SOURCEDOCUMENT_ERROR));
  }
}

export function* getRegistryRecordChangeFldsListAsync({ payload }) {
  try {
    yield put(globalActions.showSpinner());
    const { id } = payload;
    const response = yield registryApi.getRegRecordChangeFieldsList(id);

    //const { state, data } = response.data;
    if (response.status) {
      yield put(registryAction.registryRecordChangeFldsListSuccess(response.data));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(registryAction.setError());
  }
}

export function* getRegistryStampingDetailsAsync({ payload: { body } }) {
  try {
    yield put(globalActions.showSpinner());
    const response = yield registryApi.saveRegistryDetails(body);
    const { state, data } = response.data;
    if (state) {
      yield put(registryAction.getRegistryStampingDetailsSuccess({ data }));
    } else {
      yield put(globalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
    }
    yield put(globalActions.hideSpinner());
  } catch (err) {
    yield put(globalActions.hideSpinner());
    yield put(registryAction.setError());
  }
}

export function* releaseRegistryUserLockAsync({ payload }) {
  try {
    yield registryApi.unlockUserRecords(payload);
  } catch (err) {
    yield put(globalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
  }
}

export function* updateRegistryLockDetailsAsync({ payload }) {
  try {
    yield registryApi.getUserLockDetails(payload);
  } catch (err) {
    yield put(globalActions.showAlert(ALERT_MESSAGES.TRIALS_ERROR));
  }
}

export const watchers = [
  function* watchGetRegistryList() {
    yield takeEvery(registryAction.getRegistry, getRegistryDetailsAsync);
  },

  function* watchSaveRegistryAsync() {
    yield takeEvery(registryAction.saveRegistry, saveRegistryAsync);
  },

  function* watchDeleteRegistryAsyncAsync() {
    yield takeEvery(registryAction.deleteRegistry, deleteRegistryAsync);
  },

  function* watchGetRegistryMasterDataAsync() {
    yield takeEvery(registryAction.getRegistryMasterData, getRegistryMasterDataAsync);
  },

  function* watchSetRegistryAsync() {
    yield takeEvery(registryAction.saveRegistryDetails, saveRegistryAsync);
  },

  // function* watchSetRegistryAsync() {
  //   yield takeEvery(registryAction.getRegistry, getRegistryAsync);
  // },

  function* watchValidateRegistry() {
    yield takeEvery(registryAction.validateRegistry, validateRegistryAsync);
  },

  function* watchOutofScopeSourceDoc() {
    yield takeEvery(registryAction.moveOutofScopeRegistry, moveOutofScopeRegistryAsync);
  },

  function* watchGetSDStageRecordId() {
    yield takeEvery(registryAction.getStagedIdRegistry, getStagedIdRegistryAsync);
  },

  function* watchPublishSourceDoc() {
    yield takeEvery(registryAction.publishRegistry, publishRegistryAsync);
  },
  function* watchGetRegistryRecordChangeFieldsList() {
    yield takeEvery(
      registryAction.getRegistryRecordChangeFieldsList,
      getRegistryRecordChangeFldsListAsync,
    );
  },
  function* watchGetRegistryStampingDetails() {
    yield takeEvery(registryAction.getRegistryStampingDetails, getRegistryStampingDetailsAsync);
  },
  function* watchGetRegistryList() {
    yield takeEvery(registryAction.releaseRegistryUserLock, releaseRegistryUserLockAsync);
  },

  function* watchSaveRegistryAsync() {
    yield takeEvery(registryAction.updateRegistryLockDetails, updateRegistryLockDetailsAsync);
  },
];
