import { createAction } from '@reduxjs/toolkit';

// action definitions
const registryRoot = 'registry/tabs/';
export const setSelectedTabIndex = createAction(`${registryRoot}setSelectedTabIndex`);
export const registryNextFeatureSelection = createAction(`${registryRoot}goToNextFeature`);
export const registryFeatureSelection = createAction(`${registryRoot}selected`);

export const saveRegistry = createAction(`${registryRoot}saveRegistry`);
export const saveRegistrySuccess = createAction(`${registryRoot}saveRegistrySuccess`);
export const getRegistry = createAction(`${registryRoot}getRegistry`);
export const getRegistrySuccess = createAction(`${registryRoot}getRegistrySuccess`);
export const deleteRegistry = createAction(`${registryRoot}deleteRegistry`);
export const deleteRegistrySuccess = createAction(`${registryRoot}deleteRegistrySuccess`);
export const setError = createAction(`${registryRoot}error`);

export const getRegistryMasterData = createAction(`${registryRoot}getRegistryMasterData`);
export const getRegistryMasterDataSuccess = createAction(
  `${registryRoot}getRegistryMasterDataSuccess`,
);

export const getRegistryDetails = createAction(`${registryRoot}getRegistryDetails`);
export const getRegistryDetailsSuccess = createAction(`${registryRoot}getRegistryDetailsSuccess`);

export const saveRegistryDetails = createAction(`${registryRoot}saveRegistryDetails`);
export const saveRegistryDetailsSuccess = createAction(`${registryRoot}saveRegistryDetailsSuccess`);

export const resetRegistryDetails = createAction(`${registryRoot}resetRegistryDetails`);
export const resetRegistryState = createAction(`${registryRoot}resetRegistryState`);
export const setPreviewSelection = createAction(`${registryRoot}setPreviewSelection`);
export const updateRegistryUrl = createAction(`${registryRoot}updateRegistryUrl`);
export const setResultsDisclosure = createAction(`${registryRoot}setResultsDisclosure`);
export const setProtocolRegistration = createAction(`${registryRoot}protocolRegistration`);

export const validateRegistry = createAction(`${registryRoot}validateRegistry`);
export const validateRegistrySuccess = createAction(`${registryRoot}validateRegistrySuccess`);

export const setErrorDetailsRegistry = createAction(`${registryRoot}setErrorDetailsRegistry`);
export const setRegistryValidationStatus = createAction(
  `${registryRoot}setRegistryValidationStatus`,
);
export const setRegistryUnSavedStatus = createAction(`${registryRoot}setRegistryUnSavedStatus`);
export const setValidate = createAction(`${registryRoot}setValidate`);

export const moveOutofScopeRegistry = createAction(`${registryRoot}moveOutofScopeRegistry`);
export const getStagedIdRegistry = createAction(`${registryRoot}getStagedIdRegistry`);
export const moveOutofScopeRegistrySuccess = createAction(
  `${registryRoot}moveOutofScopeRegistrySuccess`,
);
export const getStagedIdRegistrySuccess = createAction(`${registryRoot}getStagedIdRegistrySuccess`);
export const resetStagedIdRegistry = createAction(`${registryRoot}resetStagedIdRegistry`);

export const publishRegistry = createAction(`${registryRoot}publishRegistry`);
export const publishRegistrySuccess = createAction(`${registryRoot}publishRegistrySuccess`);

export const getRegistryRecordChangeFieldsList = createAction(
  `${registryRoot}getRegistryRecordChangeFieldsList`,
);
export const registryRecordChangeFldsListSuccess = createAction(
  `${registryRoot}registryRecordChangeFldsListSuccess`,
);

export const getRegistryStampingDetails = createAction(`${registryRoot}getRegistryStampingDetails`);
export const getRegistryStampingDetailsSuccess = createAction(
  `${registryRoot}getRegistryStampingDetailsSuccess`,
);
export const updateRegistryRecordDatesDetail = createAction(
  `${registryRoot}updateRegistryRecordDatesDetail`,
);

// Locking Actions
export const getRegistryUserLockDetails = createAction(`${registryRoot}getRegistryUserLockDetails`);
export const releaseRegistryUserLock = createAction(`${registryRoot}releaseRegistryUserLock`);
export const resetRegistryUserLock = createAction(`${registryRoot}resetRegistryUserLock`);
export const updateRegistryLockDetails = createAction(`${registryRoot}updateRegistryLockDetails`);
