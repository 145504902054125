import { fieldTypes } from '../../../../framework/formBuilder/utils';
import { DROPDOWN_TYPES } from '../../../../utils/generic/constants';
import {
  getClassNameForIngestion,
  checkForIngestionAction,
} from '../../../../utils/generic/helper';
import { TEXT_LIMIT } from '../../../../utils/generic/constants';
export const validate = values => {
  const errors = {};
  const endDate = new Date(values?.endofApplicationDate)?.setHours(0, 0, 0, 0);
  //const endDate = eDate?.getTime();
  const internalComments1 = values.internalComments1?.replace(/(\r\n|\n|\r)/gm, '').trim() || null;
  const internalComments2 = values.internalComments2?.replace(/(\r\n|\n|\r)/gm, '').trim() || null;
  const internalComments3 = values.internalComments3?.replace(/(\r\n|\n|\r)/gm, '').trim() || null;
  const internalComments4 = values.internalComments4?.replace(/(\r\n|\n|\r)/gm, '').trim() || null;
  const internalComments5 = values.internalComments5?.replace(/(\r\n|\n|\r)/gm, '').trim() || null;
  const internalComments6 = values.internalComments6?.replace(/(\r\n|\n|\r)/gm, '').trim() || null;
  const internalComments7 = values.internalComments7?.replace(/(\r\n|\n|\r)/gm, '').trim() || null;

  const startDate = new Date(values?.effectiveDate)?.setHours(0, 0, 0, 0);
  if (values?.commentOnDocument && values?.commentOnDocument.length > 1000) {
    errors.commentOnDocument = 'Cannot enter more than 1000 characters';
  }
  if (values?.countries?.length <= 0) {
    errors.countries = 'Please enter countries';
  }
  if (values?.documentTitle === null) {
    errors.documentTitle = 'Please enter document title';
  }
  if (values.endofApplicationDate) {
    if (endDate < startDate) {
      errors.endofApplicationDate = 'End Date should be greater than or equal to Effective Date';
    }
  }
  if (values?.documentTypes?.length <= 0) {
    errors.documentTypes = 'Please enter document types';
  }
  if (values?.regulatoryStatus === null) {
    errors.regulatoryStatus = 'Please enter regulatory status';
  }
  if (values?.regulatoryVersions?.length <= 0) {
    errors.regulatoryVersions = 'Please enter regulatory versions';
  }
  if (values?.accessedDate === null) {
    errors.accessedDate = 'Please enter accessed date';
  }
  if (values?.sourceName === null) {
    errors.sourceName = 'Please enter sourceName';
  }
  if (values?.englishTranslationServices === null) {
    errors.englishVersion = 'Please select the english version/translataion source';
  }
  if (
    values.sourceDocumentInternalComments?.trim() &&
    values.sourceDocumentInternalComments.length > TEXT_LIMIT[1000]
  ) {
    errors.sourceDocumentInternalComments = 'Please enter only 1000 characters';
  }
  if (internalComments1 && internalComments1.length > TEXT_LIMIT[1000]) {
    errors.internalComments1 = 'Please enter only 1000 characters';
  }
  if (internalComments2 && internalComments2.length > TEXT_LIMIT[1000]) {
    errors.internalComments2 = 'Please enter only 1000 characters';
  }
  if (internalComments3 && internalComments3.length > TEXT_LIMIT[1000]) {
    errors.internalComments3 = 'Please enter only 1000 characters';
  }
  if (internalComments4 && internalComments4.length > TEXT_LIMIT[1000]) {
    errors.internalComments4 = 'Please enter only 1000 characters';
  }
  if (internalComments5 && internalComments5.length > TEXT_LIMIT[1000]) {
    errors.internalComments5 = 'Please enter only 1000 characters';
  }
  if (internalComments6 && internalComments6.length > TEXT_LIMIT[1000]) {
    errors.internalComments6 = 'Please enter only 1000 characters';
  }
  if (internalComments7 && internalComments7.length > TEXT_LIMIT[1000]) {
    errors.internalComments7 = 'Please enter only 1000 characters';
  }
  if (values.keyTakeaways?.trim() && values.keyTakeaways.length > TEXT_LIMIT[5000]) {
    errors.keyTakeaways = 'Please enter only 5000 characters';
  }
  if (values.documentAbstract?.trim() && values.documentAbstract.length > TEXT_LIMIT[20000]) {
    errors.documentAbstract = 'Please enter only 20000 characters';
  }
  return errors;
};

export const commentFormSchema = {
  commentOnDocument: {
    label: 'Comment on Document',
    name: 'commentOnDocument',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 3,
    },
  },
  keyTakeaways: {
    label: 'Key Takeaways',
    name: 'keyTakeaways',
    type: fieldTypes.mdEditor,
    props: {
      rowsMin: 3,
    },
  },
  documentAbstract: {
    label: 'Document Abstract',
    name: 'documentAbstract',
    type: fieldTypes.mdEditor,
    props: {
      rowsMin: 3,
    },
  },
};

export const commentLayoutSchema = {
  layout: 'row',
  spacing: 2,
  alignItems: 'flex-start',
  justify: 'flex-start',
  content: [
    {
      layout: 'commentOnDocument',
      size: 12,
    },
    {
      layout: 'keyTakeaways',
      size: 12,
    },
    {
      layout: 'documentAbstract',
      size: 12,
    },
  ],
};
export const sourceDocumentInternalCommentsFormSchema = {
  sourceDocumentInternalComments: {
    label: 'Internal Comments',
    name: 'sourceDocumentInternalComments',
    type: fieldTypes.textArea,
    props: {
      rowsMin: 3,
    },
  },
};
export const sourceDocumentInternalCommentsLayoutSchema = {
  layout: 'row',
  spacing: 2,
  alignItems: 'flex-start',
  justify: 'flex-start',
  content: [
    {
      layout: 'sourceDocumentInternalComments',
      size: 12,
    },
  ],
};

export const sourceNameFormSchema = {
  sourceName: {
    name: 'sourceName',
    type: fieldTypes.select_async,
    label: 'source name',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      isMandatory: true,
    },
  },
};

export const sourceNameLayoutSchema = {
  layout: 'row',
  spacing: 2,
  alignItems: 'flex-start',
  justify: 'flex-start',
  content: [
    {
      layout: 'sourceName',
      size: 4,
    },
  ],
};

export const initialValues = {
  id: 0,
  name: '',
  personType: null,
};

export const layoutSchema = {
  layout: 'row',
  className: 'person-basic-info-container mb-20',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'row',
      size: 12,
      className: 'person-fullname-row mt-20',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      headerProps: {
        fontStyle: 'bold',
        size: '16',
      },
      content: [
        {
          layout: 'documentTitle',
          size: 12,
          className: '',
        },
      ],
    },
    {
      layout: 'row',
      className: 'person-name-row',
      size: 12,
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      content: [
        {
          layout: 'countries',
          size: 4,
          className: '',
        },
        {
          layout: 'documentTypes',
          size: 4,
          className: '',
        },
        {
          layout: 'regulatoryStatus',
          size: 4,
        },
        {
          layout: 'internalComments1',
          size: 12,
        },
      ],
    },
  ],
};

export const layoutSchema1 = {
  layout: 'row',
  className: 'person-basic-info-container mb-20',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'row',
      size: 12,
      className: 'person-fullname-row mt-20',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      headerProps: {
        fontStyle: 'bold',
        size: '16',
      },
      content: [
        {
          layout: 'internalComments2',
          size: 12,
        },
        {
          layout: 'row',
          size: 12,
          spacing: 3,
          content: [
            {
              layout: 'additionalLinkingToRequirement',
              size: 4,
            },
          ],
        },
        {
          layout: 'regulatoryDate',
          size: 4,
        },
        {
          layout: 'effectiveDate',
          size: 4,
        },
        {
          layout: 'endofApplicationDate',
          size: 4,
        },
        {
          layout: 'internalComments3',
          size: 4,
        },
        {
          layout: 'internalComments4',
          size: 4,
        },
        {
          layout: 'internalComments5',
          size: 4,
        },
        {
          layout: 'publicationDate',
          size: 4,
        },
        {
          layout: 'regulatoryVersions',
          size: 4,
        },
        {
          layout: 'deadlineCommentDate',
          size: 4,
        },
        {
          layout: 'internalComments6',
          size: 4,
        },
        {
          layout: 'row',
          size: 12,
          spacing: 3,
          content: [
            {
              layout: 'accessedDate',
              size: 4,
            },
          ],
        },
        {
          layout: 'row',
          size: 12,
          spacing: 3,
          content: [
            {
              layout: 'internalComments7',
              size: 4,
            },
          ],
        },
      ],
    },
  ],
};

export const attachedFileLayoutSchema = {
  layout: 'row',
  className: 'person-basic-info-container mb-20',
  spacing: 3,
  justify: 'flex-start',
  alignItems: 'flex-start',
  content: [
    {
      layout: 'row',
      size: 12,
      className: 'person-fullname-row mt-20',
      spacing: 3,
      justify: 'flex-start',
      alignItems: 'flex-start',
      headerProps: {
        fontStyle: 'bold',
        size: '16',
      },
      content: [
        {
          layout: 'attachedFiles',
          size: 4,
        },
        {
          layout: 'language',
          size: 4,
        },
        {
          layout: 'button',
          size: 1,
        },
      ],
    },
  ],
};

export const formSchema = {
  documentTitle: {
    name: 'documentTitle',
    type: fieldTypes.text,
    label: 'Document title',
    props: {
      height: 'ht-lg',
      isMandatory: true,
    },
  },
  countries: {
    name: 'countries',
    type: fieldTypes.select_async,
    label: 'Country/Territory',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      isMandatory: true,
      isMulti: true,
      showSelectedData: true,
    },
  },
  documentTypes: {
    name: 'documentTypes',
    type: fieldTypes.select_async,
    label: 'DOCUMENT TYPE',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      isMulti: true,
      showSelectedData: true,
      isMandatory: true,
    },
  },
  regulatoryStatus: {
    name: 'regulatoryStatus',
    type: fieldTypes.select,
    label: 'Regulatory status',
    options: [
      { id: 1, label: 'Valid', value: 'Valid' },
      { id: 2, label: 'Outdated - still valid', value: 'Outdated - still valid' },
      { id: 3, label: 'Superseded', value: 'Superseded' },
      { id: 4, label: 'Withdrawn/Repealed', value: 'Withdrawn/Repealed' },
    ],
    props: {
      height: 'ht-lg',
      isMandatory: true,
      isSearchable: true,
      isClearable: true,
    },
  },
  internalComments1: {
    name: 'internalComments1',
    type: fieldTypes.textArea,
    label: 'INTERNAL COMMENTS',
    props: {
      rowsMin: 3,
    },
  },
};

export const attachedFileFormSchema = {
  attachedFiles: {
    name: 'attachedFiles',
    type: fieldTypes.attachFile,
    label: 'Attached Files',
    props: {
      height: 'ht-lg',
      isMandatory: true,
      multiple: true,
      acceptFileType: 'application/pdf',
    },
  },
  language: {
    name: 'language',
    type: fieldTypes.select,
    label: 'Language(s)',
    options: [
      { id: 1, label: 'English', value: 'English' },
      { id: 2, label: 'Hindi', value: 'Hindi' },
    ],
    props: {
      height: 'ht-lg',
      isMulti: true,
      isSearchable: true,
      isClearable: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      showSelectedData: true,
      isMandatory: true,
    },
  },
  button: {
    name: 'button',
    type: fieldTypes.button,
    label: '+ SUBMIT',
  },
};

export const formSchema1 = {
  internalComments2: {
    name: 'internalComments2',
    type: fieldTypes.textArea,
    label: 'INTERNAL COMMENTS',
    props: {
      rowsMin: 3,
    },
  },
  additionalLinkingToRequirement: {
    name: 'additionalLinkingToRequirement',
    type: fieldTypes.select_async,
    label: 'LINKING TO REQUIREMENT RECORD',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      isMulti: true,
      showSelectedData: true,
      isMandatory: false,
    },
  },
  regulatoryDate: {
    name: 'regulatoryDate',
    type: fieldTypes.date,
    label: 'Regulatory date',
    props: {
      format: 'dd-MMM-yyyy',
      views: ['year', 'month', 'date'],
      // isMandatory: true,
      isMandatory: false,
      clearable: true,
      // disableFuture: true,
      minDate: new Date('1200-01-01'),
      // maxDate: new Date(),
    },
  },
  effectiveDate: {
    name: 'effectiveDate',
    type: fieldTypes.date,
    label: 'Effective date / Coming into force date',
    props: {
      format: 'dd-MMM-yyyy',
      views: ['year', 'month', 'date'],
      clearable: true,
      //   disableFuture: true,
      minDate: new Date('1200-01-01'),
      // maxDate: new Date(),
    },
  },
  endofApplicationDate: {
    name: 'endofApplicationDate',
    type: fieldTypes.date,
    label: 'End of applicability date',
    props: {
      format: 'dd-MMM-yyyy',
      views: ['year', 'month', 'date'],
      clearable: true,
      //   disableFuture: true,
      minDate: new Date('1200-01-01'),
      // maxDate: new Date(),
    },
  },
  internalComments3: {
    name: 'internalComments3',
    type: fieldTypes.textArea,
    label: 'INTERNAL COMMENTS',
    props: {
      rowsMin: 3,
    },
  },
  internalComments4: {
    name: 'internalComments4',
    type: fieldTypes.textArea,
    label: 'INTERNAL COMMENTS',
    props: {
      rowsMin: 3,
    },
  },
  internalComments5: {
    name: 'internalComments5',
    type: fieldTypes.textArea,
    label: 'INTERNAL COMMENTS',
    props: {
      rowsMin: 3,
    },
  },
  publicationDate: {
    name: 'publicationDate',
    type: fieldTypes.date,
    label: 'Publication date',
    props: {
      format: 'dd-MMM-yyyy',
      views: ['year', 'month', 'date'],
      clearable: true,
      disableFuture: true,
      minDate: new Date('1200-01-01'),
      maxDate: new Date(),
    },
  },
  regulatoryVersions: {
    name: 'regulatoryVersions',
    type: fieldTypes.select_async,
    label: 'Regulatory version',
    props: {
      height: 'ht-lg',
      isSearchable: true,
      isClearable: true,
      type: DROPDOWN_TYPES.DROPDOWN_AND_TYPEAHEAD,
      isMulti: true,
      showSelectedData: true,
      isMandatory: true,
    },
  },
  deadlineCommentDate: {
    name: 'deadlineCommentDate',
    type: fieldTypes.date,
    label: 'Deadline for comments date',
    props: {
      format: 'dd-MMM-yyyy',
      views: ['year', 'month', 'date'],
      clearable: true,
      disableFuture: false,
      minDate: new Date('1200-01-01'),
      // maxDate: new Date(),
    },
    hyperlinkingSources: {
      name: 'hyperlinkingSources',
      // type: fieldTypes.select,
      label: 'HYPERLINKING SOURCES',
    },
  },
  internalComments6: {
    name: 'internalComments6',
    type: fieldTypes.textArea,
    label: 'INTERNAL COMMENTS',
    props: {
      rowsMin: 3,
    },
  },
  accessedDate: {
    name: 'accessedDate',
    type: fieldTypes.date,
    label: 'Accessed Date',
    props: {
      format: 'dd-MMM-yyyy',
      views: ['year', 'month', 'date'],
      clearable: true,
      disableFuture: false,
      minDate: new Date('1200-01-01'),
      isMandatory: true,
    },
  },
  internalComments7: {
    name: 'internalComments7',
    type: fieldTypes.textArea,
    label: 'INTERNAL COMMENTS',
    props: {
      rowsMin: 3,
    },
  },
};

// ag-grid configuration
export const columnDefs = ({
  handleChange,
  handleDelete,
  handleEdit,
  handleIngestionActions,
  disableEdit,
  gridData,
  entityName,
}) => [
  {
    headerName: 'PUBLISH ON FOS',
    width: 150,
    headerClass: ['supporting-url-fos'],
    field: 'publishOnFos',
    sortable: entityName !== 'drugs',
    cellRenderer: 'checkbox',
    cellRendererParams: params => {
      const { api, rowIndex, value, data } = params;
      return {
        id: rowIndex.toString(),
        checked: value,
        value: '',
        label: '',
        disabled: disableEdit || data.isDisabled,
        onChange: ({ isChecked }) => {
          const gridData = [];
          for (let index = 0; index < api.getDisplayedRowCount(); index++) {
            let rowData = api.getDisplayedRowAtIndex(index).data;
            rowData = {
              ...rowData,
              publishOnFos: index === rowIndex ? isChecked : rowData.publishOnFos,
            };
            gridData.push(rowData);
          }
          const rowData = {
            ...data,
            publishOnFos: isChecked,
          };
          handleChange({ gridData, rowIndex, rowData });
        },
      };
    },
  },
  {
    headerName: 'URL',
    cellClass: ({ data }) => getClassNameForIngestion(data.ingestionAction) || 'url-link',
    field: 'url',
    width: 320,
    sortable: entityName !== 'drugs',
    cellRenderer: params => {
      if (params.value.startsWith('http')) {
        return `<a href='${params.value}' target='_blank'>${params.value}</a>`;
      } else {
        return `<a href='//${params.value}' target='_blank'>${params.value}</a>`;
      }
    },
  },
  {
    headerName: 'STATUS',
    field: 'status',
    cellRenderer: 'circle',
    width: 100,
    cellRendererParams: ({ value }) => {
      let color = 'green';
      if (value && value.value && value.value.toUpperCase() === 'INACTIVE') {
        color = 'red';
      } else if (value && value.value && value.value.toUpperCase() === 'BROKEN') {
        color = 'black';
      }
      return {
        color,
      };
    },
  },
  {
    headerName: '',
    minWidth: 50,
    maxWidth: 50,
    field: '',
    cellRenderer: 'actions',
    cellRendererParams: params => {
      const { rowIndex, data } = params;
      return {
        handleEdit: () => {
          handleEdit(data, rowIndex);
        },
        canDisableEdit: disableEdit || data.isDisabled,
      };
    },
  },
  {
    headerName: '',
    minWidth: 50,
    maxWidth: 50,
    field: '',
    cellRenderer: 'actions',
    cellRendererParams: params => {
      const { rowIndex, data } = params;
      return {
        handleDelete: () => {
          handleDelete(data, rowIndex);
        },
        canDisableDelete: disableEdit || data.isDisabled,
      };
    },
  },
  {
    headerName: '',
    minWidth: 100,
    maxWidth: 100,
    field: '',
    hide: !checkForIngestionAction(gridData, true),
    cellRenderer: 'ingestionActions',
    cellRendererParams: ({ data: rowData }) => {
      return {
        ingestionAction: rowData ? rowData.ingestionAction : 'none',
        isDisabled: disableEdit,
        handleIngestionActions: option => handleIngestionActions(option, rowData),
      };
    },
  },
];

export const attachedFilesColumnDefs = ({ handleDelete, disableEdit }) => [
  {
    headerName: 'FILE NAME',
    field: 'fileName',
    width: 200,
    sortable: true,
    // cellRenderer: 'Link',
    // cellRendererParams: ({ data }) => {
    //   return {
    //     link: data.recordId ? data.recordId.toString() : '',
    //     onClick: resetAndNavigateToTrial,
    //   };
    // },
  },
  {
    headerName: 'LANGUAGE',
    field: 'language',
    width: 200,
    sortable: true,
  },
  {
    headerName: '',
    width: 50,
    field: '',
    cellRenderer: 'actions',
    cellRendererParams: ({ data }) => {
      return {
        canDisableDelete: disableEdit || data.isDisabled,
        handleDelete: () => handleDelete(data),
      };
    },
  },
  {
    headerName: '',
    width: 50,
    field: '',
    cellRenderer: 'actions',
    cellRendererParams: ({ data }) => {
      return {
        canDisableEdit: disableEdit || data.isDisabled,
        // handleEdit: () => handleEdit(data),
      };
    },
  },
];

export const translationSources = [
  {
    id: 1,
    value: 'OFFICIAL',
    label: 'Official',
  },
  {
    id: 4,
    value: 'UNOFFICIAL – MACHINE TRANSLATED',
    label: 'Unofficial – Machine translated',
  },
  {
    id: 5,
    value: 'UNOFFICIAL – CERTIFIED TRANSLATION',
    label: 'Unofficial – Certified translation',
  },
  {
    id: 6,
    value: 'UNOFFICIAL – COMBINED',
    label: 'Unofficial – Combined',
  },
  {
    id: 3,
    value: 'NOT APPLICABLE',
    label: 'Not applicable (default value)',
  },
];
