import { DROPDOWN_TYPES, TEXT_LIMIT } from '../../utils/generic/constants';
import { createCheckIsModalSelected } from '../../utils/generic/helper';

export const modalOptions = [
  'mandatoryDisclosure',
  'voluntaryDisclosure',
  'notCoveredInRegulation',
];

export const checkIsModalSelected = (arr, isMandatory) => createCheckIsModalSelected(modalOptions)(arr, isMandatory);

export const validate = (values, isValidate, isOtherTab, tab) => {
  const isTabNameSelected = values?.tabs?.some((tab) => {
    if (tab.tabId && tab.tabId !== values.tabId && values?.actAbbreviatedName?.label) {
      return tab.name?.split('-')[1]?.trim() === values?.actAbbreviatedName?.label?.trim()
    }
  })
  const errors = {};
  const studyLocationComments = values.studyLocationComments?.replace(/(\r\n|\n|\r)/gm, '').trim();
  const clinicalTrialCharacteristicsInternalComments = values.clinicalTrialCharacteristicsInternalComments
    ?.replace(/(\r\n|\n|\r)/gm, '')
    .trim();
  if (isTabNameSelected) {
    errors.actAbbreviatedName = 'The abbreviated name has been already selected.'
  }
  if (
    values?.actName?.length > TEXT_LIMIT[1000]
  ) {
    errors.actName = 'Please enter only 1000 characters';
  }
  if (
    values.studyLocationComments?.trim() &&
    studyLocationComments.trim().length > TEXT_LIMIT[3000]
  ) {
    errors.studyLocationComments = 'Please enter only 3000 characters';
  }
  if (
    values.clinicalTrialCharacteristicsInternalComments?.trim() &&
    clinicalTrialCharacteristicsInternalComments.trim().length > TEXT_LIMIT[7000]
  ) {
    errors.clinicalTrialCharacteristicsInternalComments = 'Please enter only 7000 characters';
  }
  if (isValidate) {
    const checkIsModalSelected = (arr) => createCheckIsModalSelected(modalOptions)(arr, values.isMandatory);
    if (values.isMandatory) {
      if (!values.actName && !isOtherTab) {
        errors.actName = 'Please enter act name'
      }
      if (!values.actAbbreviatedName?.label && !isOtherTab) {
        errors.actAbbreviatedName = 'Please select act abbreviated name'
      }
      if (values.studyLocationsModel.length === 0) {
        errors.studyLocationsModel = 'Please select study locations';
      }
    }

    if (isOtherTab) {
      if (
        !checkIsModalSelected(
          values.studyTypeModel?.filter(studyTypes => studyTypes?.tabId === tab?.tabId),
        )
      ) {
        errors.studyTypeModel = 'Please select study types';
      }
      if (values.isMandatory) {
        const clinical = values?.clinicalTrialCharactersDetailsModel?.find(({ tabId }) => tabId === tab?.tabId)
        if (!clinical?.actName) {
          errors.actName = 'Please enter act name'
        }
        if (!clinical?.actAbbrevName?.value) {
          errors.actAbbreviatedName = 'Please select act abbreviated name'
        }
      }
      const studyPhasesModel = values.studyPhasesModel?.filter(
        studyPhases => studyPhases?.tabId === tab?.tabId,
      );
      if (studyPhasesModel && !checkIsModalSelected(studyPhasesModel)) {
        errors.studyPhasesModel = 'Please select study phase';
      }

      if (
        !checkIsModalSelected(
          values.interventionalTypeModel?.filter(
            interventionalType => interventionalType?.tabId === tab?.tabId,
          ),
        )
      ) {
        errors.interventionalTypeModel = 'Please select interventional types';
      }
    } else {
      if (!checkIsModalSelected(values.studyTypeModel)) {
        errors.studyTypeModel = 'Please select study types';
      }
      if (values.studyPhasesModel && !checkIsModalSelected(values.studyPhasesModel)) {
        errors.studyPhasesModel = 'Please select study phase';
      }
      if (!checkIsModalSelected(values.interventionalTypeModel)) {
        errors.interventionalTypeModel = 'Please select interventional types';
      }
    }
  }
  return errors;
};
